import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CheckIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid";
import { initialFormFields } from "../utils/utils";
import { bearerToken, captchaInfoUrl, sendMailerUrl } from "../../api/mailer";
import Title from "../elements/Title";

const ContactsSection = ({
  title,
  description,
  email,
  phone,
  firstnameLabel,
  emailLabel,
  phoneLabel,
  messageLabel,
  subjectMail = "Request from short contact",
  submitButton,
  submitButtonSuccess,
  alertTitle = "Thank you!",
  alertText = "We`ve received your message. Someone from our team will contact you soon",
}) => {
  const recaptchaRef = useRef();

  const [formFields, setFormFields] = useState(initialFormFields);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [formIsSent, setFormIsSent] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const [siteKey, setSiteKey] = useState("");

  const getCaptchaInfo = async () => {
    const resp = await fetch(captchaInfoUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
    }).catch((err) => {
      new Error(err);
    });

    const data = await resp.json();

    setSiteKey(data.site_key);
  };

  useEffect(() => {
    getCaptchaInfo();
  }, []);

  const setName = (event) => {
    setFormFields({ ...formFields, name: event.target.value });
  };
  const setPhone = (event) => {
    setFormFields({ ...formFields, phone: event.target.value });
  };
  const setEmail = (event) => {
    setFormFields({ ...formFields, email: event.target.value });
  };
  const setMessage = (event) => {
    setFormFields({ ...formFields, message: event.target.value });
  };

  const onSubmit = async () => {
    const response = await fetch(sendMailerUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        ...formFields,
        subject: subjectMail,
        recaptcha: recaptchaValue,
      }),
    });
    setFormIsSent(true);

    if (response.status !== 200) {
      setIsError(true);
    } else {
      setFormFields(initialFormFields);
    }
  };

  return (
    <section className="bg-white">
      {/* Header */}
      <Title title={title} description={description} />

      <div className="relative bg-white my-16 sm:my-20">
        <div
          className="absolute w-full h-1/2 bg-warm-gray-50"
          aria-hidden="true"
        />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative bg-white shadow-xl">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              {/* Contact information */}
              <div className="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-amber-500 to-amber-600 sm:px-10 xl:p-12">
                {/* Decorative angle backgrounds */}
                <div
                  className="absolute inset-0 pointer-events-none sm:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width={343}
                    height={388}
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                      fill="url(#linear1)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear1"
                        x1="254.553"
                        y1="107.554"
                        x2="961.66"
                        y2="814.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width={359}
                    height={339}
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                      fill="url(#linear2)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                  aria-hidden="true"
                >
                  <svg
                    className="absolute inset-0 w-full h-full"
                    width={160}
                    height={678}
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="url(#linear3)"
                      fillOpacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#fff" />
                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-white">{title}</h3>
                <dl className="mt-8 space-y-6">
                  <dt>
                    <span className="sr-only">{phoneLabel}</span>
                  </dt>
                  <dd className="flex text-base text-red-50">
                    <PhoneIcon
                      className="flex-shrink-0 w-6 h-6 text-red-200"
                      aria-hidden="true"
                    />
                    <a className="ml-3" href={`tel:${phone}`}>
                      {phone}
                    </a>
                  </dd>
                  <dt>
                    <span className="sr-only">{emailLabel}</span>
                  </dt>
                  <dd className="flex text-base text-red-50">
                    <MailIcon
                      className="flex-shrink-0 w-6 h-6 text-red-200"
                      aria-hidden="true"
                    />
                    <a className="ml-3" href={`mailto:${email}`}>
                      {email}
                    </a>
                  </dd>
                </dl>
              </div>

              {/* Contact form */}
              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                {formIsSent || isError && (
                  <div
                    className={`rounded-md p-4 mb-6 ${
                      isError ? "bg-red-50" : "bg-green-50"
                    }`}
                  >
                    <div className="flex">
                      <div className="ml-3">
                        <h3
                          className={`text-sm font-medium ${
                            isError ? "text-red-800" : "text-green-800"
                          }`}
                        >
                          {isError ? "Error!" : alertTitle}
                        </h3>
                        <div
                          className={`mt-2 text-sm ${
                            isError ? "text-red-700" : "text-green-700"
                          }`}
                        >
                          <p>{isError ? "Please, try again" : alertText}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <form className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                  <div className="col-span-2 ">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {firstnameLabel}
                      <span className="text-md text-red-500 pl-2">*</span>
                    </label>
                    <div className="mt-1 ">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        required
                        onChange={setName}
                        autoComplete="given-name"
                        className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                      />
                    </div>
                  </div>

                  <div className="lg:col-span-1 col-span-2 ">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {phoneLabel}
                      <span className="text-md text-red-500 pl-2">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        id="phone"
                        name="phone"
                        type="phone"
                        required
                        onChange={setPhone}
                        autoComplete="tel"
                        className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                      />
                    </div>
                  </div>
                  <div className="lg:col-span-1 col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {emailLabel}
                      <span className="text-md text-red-500 pl-2">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        onChange={setEmail}
                        autoComplete="email"
                        className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                      />
                    </div>
                  </div>

                  <div className="col-span-2 ">
                    <div className="flex justify-between">
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {messageLabel}
                        <span className="text-md text-red-500 pl-2">*</span>
                      </label>
                    </div>
                    <div className="mt-1">
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                        aria-describedby="message-max"
                        onChange={setMessage}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 sm:flex sm:justify-end">
                    {formFields.name &&
                      formFields.email &&
                      formFields.phone &&
                      formFields.email &&
                      !isVerified && (
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          badge={"inline"}
                          sitekey={siteKey}
                          onErrored={() => setIsVerified(false)}
                          onExpired={() => setIsVerified(false)}
                          onChange={(value) => {
                            setRecaptchaValue(value);
                            setIsVerified(true);
                          }}
                        />
                      )}

                    {!formIsSent || isError ? (
                      <button
                        type="button"
                        onClick={onSubmit}
                        disabled={
                          !formFields.name ||
                          !formFields.email ||
                          !formFields.phone ||
                          !formFields.message
                        }
                        className="inline-flex justify-center items-center py-3 px-4 rounded-md shadow bg-amber-500 text-white font-medium hover:bg-amber-600 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-amber-700 focus:ring-offset-gray-900 disabled:bg-gray-200"
                      >
                        {submitButton}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="inline-flex justify-center items-center py-3 px-4 rounded-md shadow font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-green-600 hover:bg-green-700 focus:ring-green-500"
                      >
                        {submitButtonSuccess}
                        <CheckIcon className="h-6 w-6 ml-2 " />
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactsSection;
