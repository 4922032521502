import * as React from "react";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import Footer from "../components/sections/Footer";
import ContactsSection from "../components/sections/ContactsSections";

const contactsPage = () => (
  <div className="bg-white">
    <PageTitle pageTitle={"Contact with us"} description={``} />
    <Header />
    <ContactsSection
      title={"Contact with us"}
      subtitle={""}
      formTitle={""}
      phone={"+46 (0) 72 85 88 900"}
      email={"support@srv4pos.com"}
      firstnameLabel={"Your name"}
      emailLabel={"Email"}
      phoneLabel={"Phone number"}
      subjectMail={"Request from Contacts on srv4pos.com"}
      submitButton={"Submit"}
      messageLabel={"Message"}
      submitButtonSuccess={"Sent"}
      alertTitle="Thank you!"
      alertText="We`ve received your message. Someone from our team will contact you soon"
    />
    <Footer />
  </div>
);

export default contactsPage;
